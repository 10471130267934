import { Container, Title, Button, Group, Text, List, ThemeIcon, rem, Avatar, Grid } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import classes from './DoctorDetailBanner.module.css';

function DoctorDetailBanner() {
    return (
        <div className={classes.container}>
            <Container size="md" >
                <div className={classes.inner}>

                    <Avatar
                        src="https://fi.realself.com/300x300/aba3307ef329e5fa957becc8e47c68e4/4/c/e/9609268-6053592.png"
                        size={376}
                        radius={376}
                        mx="auto"
                        className={classes.avatar}
                    />

                    <div className={classes.content}>
                        <Title className={classes.title}>
                            Dr. Murat YILMAZ
                        </Title>

                        <Text className={classes.expertise} mt={'md'}>Plastic Surgeon</Text>

                        <Text c="blue.0" mt="lg">
                            We are a medical service provider based in Istanbul, Turkey and it will be our pleasure to make your dream come true in all plastic surgeries, dental treatments, bariatric surgeries and hair transplants. We have European Board Certified surgeons who are experienced in their own specialities and please do not hesitate to contact us about all question marks you have!
                        </Text>

                        <List
                            mt={30}
                            spacing="md"
                            size="md"
                            icon={
                                <ThemeIcon size={20} radius="xl">
                                    <IconCheck style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                                </ThemeIcon>
                            }
                        >
                            <List.Item c="white">
                                Practising since <b>2011</b>
                            </List.Item>
                            <List.Item c="white">
                                Treatments performed <b>4600</b>
                            </List.Item>
                            <List.Item c="white">
                                <b>English</b> and <b>German</b> support
                            </List.Item>
                        </List>

                        <Group mt={30}>
                            <Button radius="xl" size="lg" className={classes.control}>
                                Get Contact
                            </Button>
                        </Group>
                    </div>

                </div>
            </Container>
        </div>
    );
}

export default DoctorDetailBanner;