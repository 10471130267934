import { Button, Container, Title } from '@mantine/core';
import { useState } from 'react';
import { ContactFormDto, EnumContactFormTime } from './types/contactFormData';

interface TreatmentWhenProps {
    nextStep: () => void;
    contactForm: ContactFormDto;
    setTime: (treatmentTime: EnumContactFormTime) => void;
}


function TreatmentTime(props: TreatmentWhenProps) {
    const [selectedTime, setSelectedTime] = useState<EnumContactFormTime | null>(props.contactForm.time);
    const handleChange = (value: EnumContactFormTime) => {
        props.setTime(value);
        props.nextStep();
        setSelectedTime(value);
    }

  return (
        <>
            <Container size={'xs'}>

                <Title size="h4" mt="md">When would you like to have it done?</Title>

                <Button onClick={() => handleChange(EnumContactFormTime.AS_SOON_AS_POSSIBLE)} justify="center" fullWidth
                    variant={selectedTime === EnumContactFormTime.AS_SOON_AS_POSSIBLE ? 'light' : 'default'}
                    mt="md">
                    As soon as possible
                </Button>

                <Button onClick={() => handleChange(EnumContactFormTime.FEW_WEEKS)} justify="center" fullWidth
                    variant={selectedTime === EnumContactFormTime.FEW_WEEKS ? 'light' : 'default'}
                    mt="md">
                    In a few weeks
                </Button>

                <Button onClick={() => handleChange(EnumContactFormTime.FEW_MONTHS)} justify="center" fullWidth
                    variant={selectedTime === EnumContactFormTime.FEW_MONTHS ? 'light' : 'default'}
                    mt="md">
                    In a few months
                </Button>

                <Button onClick={() => handleChange(EnumContactFormTime.DONT_KNOW)} justify="center" fullWidth
                    variant={selectedTime === EnumContactFormTime.DONT_KNOW ? 'light' : 'default'}
                    mt="md">
                    I don't know yet
                </Button>

              
            </Container>
        </>
    );
}

export default TreatmentTime;