import { Timeline, Text } from '@mantine/core';

export type TimelineItem = {
    startDate: string,
    endDate: string,
    title: string,
    country: string,
}

export interface TimelineProps {
    timelineItems: TimelineItem[],
}

function TimelineInfo({ timelineItems }: TimelineProps) {

    function getDates(timelineItem: TimelineItem) {
        return [timelineItem.startDate, timelineItem.endDate].join(" - ");
    }

    return (
        <Timeline styles={{
            root: {
                paddingInlineStart: 'var(--offset)',
              },
            item: {
              paddingInlineStart: 'var(--offset)',
            },
            itemBody: {
              paddingInlineStart: 'var(--offset)',
            }
          }} active={timelineItems.length} lineWidth={3} bulletSize={18}>
            {timelineItems.map((item, index) => (
                <Timeline.Item key={index} title={getDates(item)}>
                    <Text c="dimmed" size="md">{item.title}</Text>
                    <Text size="xs" mt={4}>{item.country}</Text>
                </Timeline.Item>
            ))}
        </Timeline>

    );
}

export default TimelineInfo;