import { useEffect, useState } from 'react';
import { Stepper, Button, Group, rem } from '@mantine/core';
import axios from 'axios'
import { notifications } from '@mantine/notifications';
import { ContactFormDto, EnumContactFormCommunicationType, EnumContactFormPatient, EnumContactFormTime, EnumTreatment, TelephoneNumber } from './types/contactFormData';
import TreatmentType from './TreatmentType';
import TreatmentPatient from './types/TreatmentPatient';
import TreatmentTime from './TreatmentTime';
import TreatmentCommunicationType from './TreatmentCommunicationType';
import ContactDetail from './ContactDetails';
import { IconAlertCircle, IconAlertTriangle, IconCheck, IconError404 } from '@tabler/icons-react';

function ContactFormStepper() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [contactForm, setContactForm] = useState<ContactFormDto>({
    treatment: null,
    patient: null,
    time: null,
    communicationType: null,
    firstname: '',
    lastname: '',
    email: '',
    telephoneNumber: {
      countryCode: '',
      number: '',
    },
    note: '',
  });
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 6 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const submitForm = () => setIsFormSubmitted(true);


  useEffect(() => {
    if (isFormSubmitted) {
      const id = notifications.show({
        loading: true,
        title: 'Contact Form',
        message: 'Contact Form is sending...',
        autoClose: false,
        withCloseButton: false,
      });
      
      try {
        const formData = new FormData();
        formData.append("document", JSON.stringify(contactForm));
        files.map(f => {
          formData.append("files", f);
        })

        axios.post("https://asklepios-server.onrender.com/api/v1/contact/", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          console.log(response);
          updateSuccessNotification(id);
        }, (error) => {
          console.log(error);
          updateErrorNotification(id);
        })
      } catch (error) {
        console.error('Error submitting form:', error);
        updateErrorNotification(id);
      } finally {
        setIsFormSubmitted(false);
      }
    }
  }, [contactForm, isFormSubmitted]);

  function updateSuccessNotification(id: string) {
    notifications.update({
      id,
      color: 'teal',
      title: 'Contact Form',
      message: 'Form is sent successfully. We will contact you as soon as possible.',
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: 3000,
    });
  }

  function updateErrorNotification(id: string) {
    notifications.update({
      id,
      color: 'red',
      title: 'Contact Form',
      message: 'An error occurred while sending contact form.',
      icon: <IconAlertTriangle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: 3000,
    });
  }

  const setTreatment = (treatmentType: EnumTreatment) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        treatment: treatmentType,
      };
      return updatedInfo;
    });
  }

  const setPatient = (treatmentPatient: EnumContactFormPatient) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        patient: treatmentPatient,
      };
      return updatedInfo;
    });
  }

  const setTime = (treatmentTime: EnumContactFormTime) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        time: treatmentTime,
      };
      return updatedInfo;
    });
  }

  const setCommunicationType = (communicationType: EnumContactFormCommunicationType) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        communicationType: communicationType,
      };
      return updatedInfo;
    });
  }

  const setName = (name: string) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        firstname: name,
      };
      return updatedInfo;
    });
  }

  const setLastName = (lastname: string) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        lastname: lastname,
      };
      return updatedInfo;
    });
  }

  const setEmail = (email: string) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        email: email,
      };
      return updatedInfo;
    });
  }

  const setNote = (note: string) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        note: note,
      };
      return updatedInfo;
    });
  }

  const setTelephoneNumber = (telephoneNumber: TelephoneNumber) => {
    setContactForm((currentInfo) => {
      const updatedInfo = {
        ...currentInfo,
        telephoneNumber: telephoneNumber,
      };
      return updatedInfo;
    });
  }

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Treatment" description="Select the treatment">
          <TreatmentType nextStep={nextStep} setTreatment={setTreatment} contactForm={contactForm}></TreatmentType>
        </Stepper.Step>
        <Stepper.Step label="Patient" description="Select the patient">
          <TreatmentPatient nextStep={nextStep} setPatient={setPatient} contactForm={contactForm}></TreatmentPatient>
        </Stepper.Step>
        <Stepper.Step label="When" description="Select the requested time">
          <TreatmentTime nextStep={nextStep} setTime={setTime} contactForm={contactForm}></TreatmentTime>
        </Stepper.Step>
        <Stepper.Step label="Communication Type" description="Select the communication type">
          <TreatmentCommunicationType nextStep={nextStep} setCommunicationType={setCommunicationType} contactForm={contactForm}></TreatmentCommunicationType>
        </Stepper.Step>
        <Stepper.Step label="Final step" description="Contact Details">
          <ContactDetail contactForm={contactForm} setName={setName} setLastName={setLastName} setEmail={setEmail} setNote={setNote} setTelephoneNumber={setTelephoneNumber} setFiles={setFiles} submitForm={submitForm}></ContactDetail>
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>

      <Group justify="center" mt="xl">
        <Button variant="default" size='md' pos={'absolute'} bottom={10} onClick={prevStep}>Back</Button>
      </Group>
    </>
  );
}

export default ContactFormStepper;