import {
    Group,
    Button,
    Divider,
    Box,
    Burger,
    Drawer,
    ScrollArea,
    Image,
    rem,
    Anchor,
  } from '@mantine/core';
  import { useDisclosure } from '@mantine/hooks';
  import '@mantine/core/styles.css';
  import classes from './HeaderMenu.module.css';
  import logo from "../../assets/images/logo/asklepios-log-bg-test.png";
import { useNavigate } from 'react-router-dom';
  
  export function HeaderMenu() {
    const navigation = useNavigate();
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);   
  
    const navigateTo = (url: String) => {
      navigation(`/${url}`);
    };

    return (
      <Box >
        <header className={classes.header}>
          <Group justify="space-between" h="100%">
                      
            <Image className={classes.logo} w={600} h={60} src={logo} onClick={() => navigateTo("")}></Image>
          
            <Group h="100%" gap={0} visibleFrom="sm">
              <a href="/" className={classes.link}>
                Home
              </a>          
              <a href="#" className={classes.link}>
                How It Works
              </a>
              <a href="/contactFormRequest" className={classes.link}>
                Requests
              </a>
            </Group>
  
            <Group visibleFrom="sm">
              <Button onClick={() => navigateTo("login")} variant="default">Log in</Button>
              <Button>Sign up</Button>
            </Group>
  
            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
          </Group>
        </header>
  
        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          hiddenFrom="sm"
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
            <Divider my="sm" />
  
            <a href="#" className={classes.link}>
              Home
            </a>
            
            <a href="#" className={classes.link}>
              About Us
            </a>

            <a href="#" className={classes.link}>
              Services
            </a>
           
            <Divider my="sm" />
            
            <Group justify="center" grow pb="xl" px="md">
            <Button variant="default">Log in1</Button>
              <Button variant="default">Log in</Button>
              <Button>Sign up</Button>
            </Group>
          </ScrollArea>
        </Drawer>
      </Box>
    );
  }

  export default HeaderMenu;