import { Badge, Box, Center, Text, Pagination, Table, Group, TextInput, ActionIcon, rem, Select, Modal, Menu } from "@mantine/core";
import { IconCheck, IconDeviceWatch, IconDots, IconGlass, IconHourglass, IconHourglassEmpty, IconHourglassFilled, IconHourglassHigh, IconHourglassLow, IconHourglassOff, IconMessageReply, IconSearch, IconSubtask, IconTicTac, IconTimeDuration0, IconTimeDuration45, IconTimeline, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import ContactFormRequestContent from "../../components/contact-form/ContactFormRequestContent";
import { ContactFormRequestData } from '../../components/contact-form/types/contactFormRequest';

function ContactFormRequest() {
    const [opened, { open, close }] = useDisclosure(false);
    const [selectedContactRequest, setSelectedContactRequest] = useState<ContactFormRequestData | null>();
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const states: Record<string, string> = {
        PENDING: 'red',
        REPLIED: 'green',
        DONE: 'blue',
        DELETED: 'grey',
    };

    const requests = [
        {
            id: 1,
            name: 'Selin',
            surname: 'Karaca',
            treatment: 'PLASTIC SURGERY',
            email: 'selinkaraca@gmail.com',
            telephone: '+90 507 123 12 13',
            time: 'As soon as possible',
            communicationType: 'EMAIL',
            note: 'test note about patient for Selin.',
            photos: '3 photos',
            date: '03.09.2024 - 09:00',
            status: 'PENDING',
        },
        {
            id: 2,
            name: 'Ahmet',
            surname: 'Karaca',
            treatment: 'HAIR TRANSPLANTATION',
            email: 'ahmetkaraca@gmail.com',
            telephone: '+90 507 123 12 13',
            time: 'Few Weeks',
            communicationType: 'WHATSAPP',
            note: 'test note about patient for Ahmet.',
            photos: '1 photo',
            date: '03.09.2024 - 10:00',
            status: 'REPLIED',
        },
        {
            id: 3,
            name: 'Mehmet',
            surname: 'Kara',
            treatment: 'PLASTIC SURGERY',
            email: 'mehmetkaraca@gmail.com',
            telephone: '+90 507 123 12 13',
            time: 'As soon as possible',
            communicationType: 'PHONE',
            note: 'test note about patient for Mehmet.',
            photos: '2 photos',
            date: '03.09.2024 - 11:00',
            status: 'DONE',
        }

    ];

    function openRequestDetail(id: number) {
        open();
    }

    const rows = requests.map((item) => (
        <Table.Tr key={item.name}>
            <Table.Td onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="md">{item.name} {item.surname}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Badge color={'blue'} variant="light">
                    {item.treatment}
                </Badge>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.email}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.telephone}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.time}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.communicationType}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.note}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.photos}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Text fz="sm">{item.date}</Text>
            </Table.Td>
            <Table.Td  onClick={() => openRequestDetail(item.id)} style={{ cursor: 'pointer' }}>
                <Badge color={states[item.status]} variant="light">
                    {item.status}
                </Badge>
            </Table.Td>
            <Table.Td>
                <Menu
                    transitionProps={{ transition: 'pop' }}
                    withArrow
                    position="bottom-end"
                    withinPortal
                >
                    <Menu.Target>
                        <ActionIcon variant="subtle" color="gray">
                            <IconDots style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            leftSection={
                                <IconHourglass style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                            }
                        >
                            Pending
                        </Menu.Item>
                        <Menu.Item
                            leftSection={<IconMessageReply style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                        >
                            Replied
                        </Menu.Item>
                        <Menu.Item
                            leftSection={
                                <IconCheck style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                            }
                        >
                            Done
                        </Menu.Item>
                        <Menu.Item
                            leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            color="red"
                        >
                            Deleted
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <div>
            <Box ml={{ base: '25', md: '25', lg: '80', xl: '100' }} mr={{ base: '25', md: '25', lg: '80', xl: '100' }} mt={"lg"}>
                <Group justify="space-between" wrap="nowrap">
                    <TextInput
                        placeholder="Search Requests"
                        width='100%'
                        w={'100%'}
                        size="lg"
                        radius={"lg"}
                        onChange={(e) => setQuery(e.target.value)}
                        leftSection={<IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
                    />
                    <ActionIcon
                        variant="gradient"
                        size="xl"
                        aria-label="Gradient action icon"
                        radius={"md"}
                        gradient={{ from: 'blue', to: 'green' }}
                    >
                        <IconSearch />
                    </ActionIcon>
                </Group>
                <Table.ScrollContainer minWidth={800} mt={"lg"}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false} verticalSpacing="sm">
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Name</Table.Th>
                                <Table.Th>Treatment</Table.Th>
                                <Table.Th>Email</Table.Th>
                                <Table.Th>Telephone</Table.Th>
                                <Table.Th>Time</Table.Th>
                                <Table.Th>Communication</Table.Th>
                                <Table.Th>Note</Table.Th>
                                <Table.Th>Photos</Table.Th>
                                <Table.Th>Date</Table.Th>
                                <Table.Th>Status</Table.Th>
                                <Table.Th />
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
                <Center>
                    <Pagination onChange={(newPage) => setPage(newPage - 1)} total={totalPages} />
                </Center>

            </Box>
            <Modal
                opened={opened}
                onClose={close}
                fullScreen
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <ContactFormRequestContent></ContactFormRequestContent>
            </Modal>
        </div>
    )
}

export default ContactFormRequest;