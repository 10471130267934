import { Banner } from '../../components/banner/Banner';
import PopularClinics from '../../components/clinic/PopularClinics';
import PopularDoctors from '../../components/doctor/PopularDoctors';


function Home() {
    return (
        <div>
            <Banner />
            <PopularDoctors></PopularDoctors>
            <PopularClinics></PopularClinics>
        </div>
    );
}

export default Home;