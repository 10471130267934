export interface ContactFormDto {
  treatment: EnumTreatment | null;
  patient: EnumContactFormPatient | null;
  time: EnumContactFormTime | null;
  communicationType: EnumContactFormCommunicationType | null;
  firstname: string;
  lastname: string;
  email: string;
  telephoneNumber: TelephoneNumber;
  note: string;
}
export interface TelephoneNumber {
  countryCode: string;
  number: string;
}

export enum EnumTreatment {
  ORAL_DENTAL_HEALTH = 'ORAL_DENTAL_HEALTH',
  EYE_HEALTH = 'EYE_HEALTH',
  HAIR_TRANSPLANTATION = 'HAIR_TRANSPLANTATION',
  ORTHOPEDICS = 'ORTHOPEDICS',
  OBESITY_SURGERY = 'OBESITY_SURGERY',
  PLASTIC_SURGERY = 'PLASTIC_SURGERY',
  RHINOPLASTY = 'RHINOPLASTY',
  BREAST_SURGERY = 'BREAST_SURGERY',
  REPRODUCTIVE_MEDICINE = 'REPRODUCTIVE_MEDICINE',
  CHECK_UP = 'CHECK_UP',
  OTHER = 'OTHER',
}

export enum EnumContactFormPatient {
  MYSELF = 'MYSELF',
  SOMEONE_ELSE = 'SOMEONE_ELSE',
}

export enum EnumContactFormTime {
  AS_SOON_AS_POSSIBLE = 'AS_SOON_AS_POSSIBLE',
  FEW_WEEKS = 'FEW_WEEKS',
  FEW_MONTHS = 'FEW_MONTHS',
  DONT_KNOW = 'DONT_KNOW',
}

export enum EnumContactFormCommunicationType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
}


