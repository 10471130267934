import { Container, Title, Text, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import classes from './Banner.module.css';
import ContactFormStepper from '../contact-form/ContactFormStepper';

export function Banner() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div className={classes.root}>
      <Container ml={150}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Find{' '}
              <Text
                component="span"
                inherit
                variant="gradient"
                gradient={{ from: 'blue', to: 'green' }}
              >
                board-certified
              </Text>{' '}
              plastic surgeons and other cosmetic treatment providers!
            </Title>

            <Button
              onClick={open}
              variant="gradient"
              gradient={{ from: 'blue', to: 'green' }}
              size="xl"
              className={classes.control}
              mt={40}
            >
              Get started
            </Button>
            <Modal
              opened={opened}
              onClose={close}
              title="Contact Form"
              fullScreen
              radius={0}
              transitionProps={{ transition: 'fade', duration: 200 }}
            >
              {/* Modal content */}
              <ContactFormStepper ></ContactFormStepper>
            </Modal>
          </div>
        </div>
      </Container>
    </div>
  );
}