import { Avatar, Box, Group, rem, Table, TextInput, Text, ActionIcon, Anchor, Badge, Center } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import classes from './DoctorSearch.module.css';
import { Pagination } from '@mantine/core';
import { useEffect, useState } from "react";
import { searchDoctors } from "../../components/doctor/api/searchDoctors";

function DoctorSearch() {
    const navigation = useNavigate();
    const [query, setQuery] = useState('');
    const [fetchedDoctors, setFetchedDoctors] = useState();
    const [searchCriteria, setSearchCriteria] = useState('');
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigateTo = (id: React.Key) => {
        navigation(`/doctor/${id}`);
    };

    useEffect(() => {
        fetchDoctors();
    }, [page]);

    const handleSearch = () => {
        setPage(0);
        fetchDoctors();
    };

    const fetchDoctors = async () => {
        setLoading(true);
        try {
            const data = await searchDoctors(query, page, 5);
            setFetchedDoctors(data.content);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        } finally {
            setLoading(false);
        }   
    };

    const doctors = [
        {        
            name: 'Dr. Murat Yılmaz',
            expertise: 'Plastic Surgeon',
            location: 'Turkey - İstanbul',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/186e0cc65cb6888f96fa4798bd928780/7/3/3/7739458-6056813.jpeg',
        },
        {
            name: 'Dr. Ahmet Karaca',
            expertise: 'Hair Restoration Surgeon',
            location: 'Turkey - Ankara',
            number: '+90 505 321 54 76',
            image: 'https://fi.realself.com/300x300/aba3307ef329e5fa957becc8e47c68e4/4/c/e/9609268-6053592.png',
        },
        {
            name: 'Prof. Dr. Aylin Sarı',
            expertise: 'Plastic Surgeon',
            location: 'Turkey - İstanbul',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/eeac2f305b0c357d01915b1914081137/2/f/f/13111129-5979765.jpeg',
        },
        {
            name: 'Dr. Salih Seven',
            expertise: 'Dentist',
            location: 'Turkey - İzmir',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/0fb9d6e001ff66274ae66745fba52317/4/7/a/12191085-5980393.jpeg',
        }, 
        {
            name: 'Dr. Ahmet Yılmaz',
            expertise: 'Plastic Surgeon',
            location: 'Turkey - İstanbul',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/186e0cc65cb6888f96fa4798bd928780/7/3/3/7739458-6056813.jpeg',
        },
        {
            name: 'Dr. Mehmet Karaca',
            expertise: 'Hair Restoration Surgeon',
            location: 'Turkey - Ankara',
            number: '+90 505 321 54 76',
            image: 'https://fi.realself.com/300x300/aba3307ef329e5fa957becc8e47c68e4/4/c/e/9609268-6053592.png',
        },
        
    ];

    const rows = doctors.map((item) => (
        <Table.Tr key={item.name}>
            <Table.Td>
                <Group gap="sm">
                    <Avatar size={75} src={item.image} radius={75} />
                    <Anchor underline="never" c={'black'} size="lg" fw={500} onClick={() => { navigateTo(1) }}>
                        {item.name}
                    </Anchor>
                </Group>
            </Table.Td>

            <Table.Td>
                <Badge color={'blue'} variant="light">
                    {item.expertise}
                </Badge>
            </Table.Td>
            <Table.Td>
                <Text fz="md">{item.location}</Text>
            </Table.Td>
            <Table.Td>
                <Text fz="md">{item.number}</Text>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <div>
            <Box ml={{ base: '25', md: '25', lg: '80', xl: '100' }} mr={{ base: '25', md: '25', lg: '80', xl: '100' }} mt={"lg"}>
                <Group justify="space-between" wrap="nowrap">
                    <TextInput
                        placeholder="Search Doctors"
                        width='100%'
                        w={'100%'}
                        size="lg"
                        radius={"lg"}
                        onChange={(e) => setQuery(e.target.value)}
                        leftSection={<IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
                    />
                    <ActionIcon
                        onClick={handleSearch}
                        variant="gradient"
                        size="xl"
                        aria-label="Gradient action icon"
                        radius={"md"}                       
                        gradient={{ from: 'blue', to: 'green' }}
                    >
                        <IconSearch />
                    </ActionIcon>
                </Group>
                <Table.ScrollContainer minWidth={800} mt={"lg"}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false} verticalSpacing="sm">
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Name</Table.Th>
                                <Table.Th>Expertise</Table.Th>
                                <Table.Th>Location</Table.Th>
                                <Table.Th>Phone</Table.Th>
                                <Table.Th />
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
                <Center>
                    <Pagination  onChange={(newPage) => setPage(newPage - 1)} total={totalPages}/>
                </Center>
                
            </Box>
        </div>
    );
}

export default DoctorSearch;