import React from 'react';
import '@mantine/core/styles.css';
import classes from './PopularDoctors.module.css'
import { Box, Button, Divider, Grid, Group, Paper, Title } from '@mantine/core';
import { DoctorCard } from './DoctorCard';
import { IconArrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

function PopularDoctors() {
    const navigation = useNavigate();
    
    const navigateTo = () => {
        navigation(`/doctors`);
      };

    const doctors = [
        {
            name: 'Dr. Murat Yılmaz',
            expertise: 'Plastic Surgeon',
            location: 'Turkey - İstanbul',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/186e0cc65cb6888f96fa4798bd928780/7/3/3/7739458-6056813.jpeg',
        },
        {
            name: 'Dr. Ahmet Karaca',
            expertise: 'Hair Restoration Surgeon',
            location: 'Turkey - Ankara',
            number: '+90 505 321 54 76',
            image: 'https://fi.realself.com/300x300/aba3307ef329e5fa957becc8e47c68e4/4/c/e/9609268-6053592.png',
        },
        {
            name: 'Prof. Dr. Aylin Sarı',
            expertise: 'Plastic Surgeon',
            location: 'Turkey - İstanbul',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/eeac2f305b0c357d01915b1914081137/2/f/f/13111129-5979765.jpeg',
        },
        {
            name: 'Dr. Salih Seven',
            expertise: 'Dentist',
            location: 'Turkey - İzmir',
            number: '+90 505 123 45 67',
            image: 'https://fi.realself.com/300x300/0fb9d6e001ff66274ae66745fba52317/4/7/a/12191085-5980393.jpeg',
        },
    ];

    return (
        <div className={classes.container}>
            <Box ml={{ base: '25', md: '25', lg: '80', xl: '100' }} mr={{ base: '25', md: '25', lg: '80', xl: '100' }} pt={'lg'}>
                <Paper withBorder shadow="md" p="sm" radius="md">
                    <Group mb={'md'} justify="space-between">
                        <Title className={classes.timelineTitle}>Popular Doctors</Title>
                        <Button
                            onClick={navigateTo}
                            variant="default"
                            rightSection={<IconArrowRight size={14} />}>
                            See All Doctors
                        </Button>
                    </Group>

                    <Divider my="md" />
                    <Grid>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl:2 }}><DoctorCard doctor={doctors[0]}></DoctorCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl:2 }}><DoctorCard doctor={doctors[1]}></DoctorCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl:2 }}><DoctorCard doctor={doctors[2]}></DoctorCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl:2 }}><DoctorCard doctor={doctors[3]}></DoctorCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl:2 }}><DoctorCard doctor={doctors[1]}></DoctorCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl:2 }}><DoctorCard doctor={doctors[2]}></DoctorCard></Grid.Col>
                    </Grid>                    
                </Paper>
            </Box>

            {/**    <Title className={classes.title} ta="start" mt="sm">Popular Doctors</Title>

            <Grid>
                <Grid.Col span="auto"><DoctorCard doctor={doctors[0]}></DoctorCard></Grid.Col>
                <Grid.Col span="auto"><DoctorCard doctor={doctors[1]}></DoctorCard></Grid.Col>
                <Grid.Col span="auto"><DoctorCard doctor={doctors[2]}></DoctorCard></Grid.Col>
                <Grid.Col span="auto"><DoctorCard doctor={doctors[3]}></DoctorCard></Grid.Col>
                <Grid.Col span="auto"><DoctorCard doctor={doctors[1]}></DoctorCard></Grid.Col>
                <Grid.Col span="auto"><DoctorCard doctor={doctors[2]}></DoctorCard></Grid.Col>
            </Grid>*/}

            {/**  
            <Carousel
                withIndicators            
                slideSize={{ base: '100%', sm: '50%', md: '33.3333%' }}
                slideGap={{ base: 0, sm: 'md' }}
                loop
                align="start"
            >
                <Carousel.Slide><UserInfoAction doctor={doctors[0]}></UserInfoAction></Carousel.Slide>
                <Carousel.Slide><UserInfoAction doctor={doctors[1]}></UserInfoAction></Carousel.Slide>
                <Carousel.Slide><UserInfoAction doctor={doctors[2]}></UserInfoAction></Carousel.Slide>
                <Carousel.Slide><UserInfoAction doctor={doctors[3]}></UserInfoAction></Carousel.Slide>
                <Carousel.Slide><UserInfoAction doctor={doctors[0]}></UserInfoAction></Carousel.Slide>  
            </Carousel>
*/}
        </div>
    );
}

export default PopularDoctors;
