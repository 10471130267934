import { Carousel } from '@mantine/carousel';
import { Card, Group, Text, Menu, ActionIcon, Image, SimpleGrid, rem } from '@mantine/core';
import { IconDots, IconEye, IconFileZip, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';

const images = [
    'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-1.png',
    'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-2.png',
    'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png',
    'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-1.png',
    'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-2.png',
    'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png',
];

function BeforeAfterGallery() {
    const [previewPhoto, setPreviewPhoto] = useState(images[0]);
    const changePreviewPhoto = (src: string) => {
        setPreviewPhoto(src);
    }

    return (
        <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder inheritPadding py="xs">
                <Group justify="space-between">
                    <Text fw={500}>Before After Photos</Text>
                    <Menu withinPortal position="bottom-end" shadow="sm">
                        <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                                <IconDots style={{ width: rem(16), height: rem(16) }} />
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item leftSection={<IconFileZip style={{ width: rem(14), height: rem(14) }} />}>
                                Download zip
                            </Menu.Item>
                            <Menu.Item leftSection={<IconEye style={{ width: rem(14), height: rem(14) }} />}>
                                Preview all
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                color="red"
                            >
                                Delete all
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Card.Section>

            <Card.Section mt="sm">
                <Image src={previewPhoto} />
            </Card.Section>

            <Card.Section inheritPadding mt="sm" pb="md">
                <Carousel
                    styles={{
                        controls: { insetInlineStart: 0, insetInlineEnd: 0 },
                        indicators: { insetInlineStart: 0, insetInlineEnd: 0 }
                    }}
                    withIndicators
                    height={200}
                    slideSize={{ base: '100%', sm: '50%', md: '33.333333%' }}
                    slideGap={{ base: 0, sm: 'md' }}
                    loop
                    align="start"
                >
                    {images.map((image) => (
                        <Carousel.Slide> <Image src={image} key={image} radius="sm" onClick={() => changePreviewPhoto(image)} /></Carousel.Slide>
                    ))}

                    {/* ...other slides */}
                </Carousel>
            </Card.Section>
        </Card>
    );
}

export default BeforeAfterGallery;