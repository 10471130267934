import { Card, Image, Text, Group, Badge, Button } from '@mantine/core';
import classes from './ClinicCard.module.css';
import { useNavigate } from 'react-router-dom';

export type Clinic = {
  name: string,
  description: string,
  address: string,
  image: string,
  services: string[],
}

export interface ClinicProps {
  clinic: Clinic;
}

export function ClinicCard(props: ClinicProps) {
  const navigation = useNavigate();
  const clinic = props.clinic;
  
  const features = clinic.services.map((badge) => (
    <Badge variant="light" key={badge}>
      {badge}
    </Badge>
  ));

  const navigateTo = (id: React.Key) => {
    navigation(`/clinic/${id}`);
  };

  return (
    <Card withBorder radius="md" p="md" className={classes.card} bg="var(--mantine-color-body)" >
      <Card.Section>
        <Image src={clinic.image} alt={clinic.name} height={180}  onClick={() => navigateTo(1)}/>
      </Card.Section>

      <Card.Section className={classes.descriptionSection} mt="md">
          <Text fz="lg" fw={500}>
            {clinic.name}
          </Text>
        <Badge size="xs" variant="light">
            {clinic.address}
          </Badge>
        <Text fz="sm" mt="xs">
          {clinic.description}
        </Text>
      </Card.Section>

      <Card.Section className={classes.treatmentsSection}>
        <Text mt="md" className={classes.label} c="dimmed">
          Treatments
        </Text>
        <Group gap={7} mt={5}>
          {features}
        </Group>
      </Card.Section>

      <Group mt="xs">
        <Button radius="md" style={{ flex: 1 }}>
          Get Contact
        </Button>
      </Group>
    </Card>
  );
}

export default ClinicCard;