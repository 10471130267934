import { useState } from 'react';
import { FileButton, Button, Group, Text } from '@mantine/core';

interface ContactDetailsProps {
  uploadFiles: (files: File[]) => void
}

function FileUpload(props: ContactDetailsProps) {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  
  const upload = (files: File[]) => {
    setUploadedFiles(files);
    props.uploadFiles(files);
  }

  return (
    <>
      <Group justify="center">
        <FileButton onChange={upload} accept="image/png,image/jpeg" multiple>
          {(props) => <Button {...props}>Upload Image</Button>}
        </FileButton>
      </Group>  

      <ul>
        {uploadedFiles.map((file, index) => (
          <Text size='xs' key={index}>{file.name}</Text>          
        ))}
      </ul>
    </>
  );
}

export default FileUpload;