import { Divider, Paper, Title, Text } from "@mantine/core";
import classes from './ClinicDetailAbout.module.css';

function ClinicDetailAbout() {
    return (
        <div>
            <Paper withBorder shadow="md" p="sm" radius="md">
                <Title mb={'md'} className={classes.timelineTitle}>About The Clinic</Title>
                <Divider my="md" />

                <Text className="classes.about">

                    Flora Clinic offers a wide range of plastic surgery treatments, as well as bariatric surgery. Since opening in 2018, they have remained dedicated to providing outstanding personalised care. Many international patients travel to them for procedures like hair transplantation, breast surgery, rhinoplasty, abdominoplasty, face lifting and liposuction.

                    The clinic collaborates with a few hospitals in Istanbul to perform its procedures. The bulk of their plastic surgeries are performed in the internationally accredited LIV Ulus hospital by Dr. Orkun Uyanik, but rhinoplasties are done by Dr. Mehmet Palali at Gunesli Erdem Hospital, which was newly built in 2015 to the highest specs of modern medicine.

                    Flora's hair transplant surgery is performed on their own permises, with the capacity to carry out a maximum of eight transplants per day. They use the DHI and FUE methods and are proficient in eyebrow, beard and female hair transplants too. However, their major advantage in this field is the use of their own patented step in the process called the 'Vita Protocol', which their surgeons developed to improve the survival rate of extracted hair grafts. First, a serum is applied to the donor area and absorption is maximised with a dermaroller. Then, the grafts are extracted and subject to the same serum once again, nurturing them with minimal damage. Finally, PRP is injected into the area that the grafts have been implanted into, promoting their successful growth and a healthy recovery.
                </Text>
            </Paper>
        </div>
    )
}

export default ClinicDetailAbout;