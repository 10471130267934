import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    Image,
    rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import logo from "../../assets/images/logo/asklepios-log-bg-test.png";
import classes from './Login.module.css';
import { loginRequest } from './api/loginRequest';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';

function Login() {
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const [accessToken, setAccessToken] = useState();
    const [refreshToken, setRefreshToken] = useState();

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    const navigateTo = () => {
        navigation(`/contactFormRequest`);
    };

    const handleSubmit = (values: typeof form.values) => {
        console.log("Email: " + values.email);
        console.log("Password: " + values.password);
        loginOperation(values.email, values.password);
    };

    const loginOperation = async (email: string, password: string) => {
        setLoading(true);
        const id = notifications.show({
            loading: true,
            title: 'Authentication',
            message: 'Login request is pending...',
            autoClose: false,
            withCloseButton: false,
          });
        try {
            const response = await loginRequest(email, password);
            if (response.status === 200) {
                updateSuccessNotification(id);
                setAccessToken(response.data.access_token);
                setRefreshToken(response.data.refresh_token);
                navigateTo();
            } else {
                updateErrorNotification(id);
                console.log('Response:', response);
            }
        } catch (error) {
            updateErrorNotification(id);
            console.log('Error...', error);
        } finally {            
            setLoading(false);
        }
    }

    function updateSuccessNotification(id: string) {
        notifications.update({
          id,
          color: 'teal',
          title: 'Authentication',
          message: 'Authentication completed succesfully!',
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
          autoClose: 3000,
        });
      }

    function updateErrorNotification(id: string) {
        notifications.update({
          id,
          color: 'red',
          title: 'Authentication',
          message: 'Login failed. Check your email and password!',
          icon: <IconAlertTriangle style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
          autoClose: 3000,
        });
      }

    return (
        <Container size={420} my={40}>
            <Image src={logo}></Image>

            {/** 
        <Text c="dimmed" size="sm" ta="center" mt={5}>
          Do not have an account yet?{' '}
          <Anchor size="sm" component="button">
            Create account
          </Anchor>
        </Text>
        */}

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                    <TextInput label="Email" placeholder="your@email.com" key={form.key('email')}  {...form.getInputProps('email')} required />
                    <PasswordInput label="Password" placeholder="Your password" key={form.key('password')}  {...form.getInputProps('password')} required mt="md" />
                    <Group justify="space-between" mt="lg">
                        <Checkbox label="Remember me" />
                        <Anchor component="button" size="sm">
                            Forgot password?
                        </Anchor>
                    </Group>
                    <Button type='submit' fullWidth mt="xl">
                        Sign in
                    </Button>
                </form>
            </Paper>
        </Container>
    );
}

export default Login;