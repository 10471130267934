import React from 'react';
import '@mantine/core/styles.css';
import classes from './PopularClinics.module.css'
import { Box, Button, Divider, Grid, Group, Paper, Title } from '@mantine/core';
import { ClinicCard } from './ClinicCard';
import { IconArrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

function PopularClinics() {
    const navigation = useNavigate();

    const navigateTo = () => {
        navigation(`/clinics`);
    };

    const clinics = [
        {
            name: 'Flora Clinic',
            description: 'We offer special solutions to our patients with our contracted, modern facilities and innovative approach. Your health is our priority.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1631248055158-edec7a3c072b?q=80&w=2061&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Hair Transplant", "Breast Augmentation"
            ],
        },
        {
            name: 'Vanity Cosmetic Surgery',
            description: 'Vanity surgeons are member of international plastic surgery societies such as ISAPS and EBOPRAS.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1519494026892-80bbd2d6fd0d?q=80&w=3428&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Liposuction", "Gastric Sleeve Surgery"
            ],
        },
        {
            name: 'Estpoint',
            description: 'Together with our physicians and team members who are experts in their fields, we provide service with a patient-oriented service approach and multidisciplinary perspective.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1516549655169-df83a0774514?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Hip Replacement", "Hair Transplant"
            ],
        },
        {
            name: 'Estethica',
            description: 'Take a look at our surgical and non-surgical aesthetics, healthy living, hair transplantation and dental aesthetics services.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1524222928538-afb4409a0d70?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Dental Implants"
            ],
        },
    ];

    return (
        <div className={classes.container}>
            <Box ml={{ base: '25', md: '25', lg: '80', xl: '100' }} mr={{ base: '25', md: '25', lg: '100', xl: '100' }} pt={'lg'} mb={'lg'}>
                <Paper withBorder shadow="md" p="sm" radius="md">
                    <Group mb={'md'} justify="space-between">
                        <Title className={classes.timelineTitle}>Popular Clinics</Title>
                        <Button
                            onClick={navigateTo}
                            variant="default"
                            rightSection={<IconArrowRight size={14} />}>
                            See All Clinics
                        </Button>
                    </Group>
                    <Divider my="md" />
                    <Grid>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl: 2 }}><ClinicCard clinic={clinics[0]}></ClinicCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl: 2 }}><ClinicCard clinic={clinics[1]}></ClinicCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl: 2 }}><ClinicCard clinic={clinics[2]}></ClinicCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl: 2 }}><ClinicCard clinic={clinics[3]}></ClinicCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl: 2 }}><ClinicCard clinic={clinics[1]}></ClinicCard></Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4, xl: 2 }}><ClinicCard clinic={clinics[2]}></ClinicCard></Grid.Col>
                    </Grid>
                </Paper>

            </Box>

            {/**    <Title className={classes.title} ta="start" mt="sm">Popular Clinics</Title>           
            <Grid>
                <Grid.Col span="auto"><ClinicCard clinic={clinics[0]}></ClinicCard></Grid.Col>
                <Grid.Col span="auto"><ClinicCard clinic={clinics[1]}></ClinicCard></Grid.Col>
                <Grid.Col span="auto"><ClinicCard clinic={clinics[2]}></ClinicCard></Grid.Col>
                <Grid.Col span="auto"><ClinicCard clinic={clinics[3]}></ClinicCard></Grid.Col>
                <Grid.Col span="auto"><ClinicCard clinic={clinics[0]}></ClinicCard></Grid.Col>
                
            </Grid> */}

        </div>
    );
}

export default PopularClinics;
