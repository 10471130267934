import { Avatar, Box, Group, rem, Table, TextInput, Text, ActionIcon, Anchor, Badge, Center, AspectRatio, Image } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Pagination } from '@mantine/core';

function ClinicSearch() {
    const navigation = useNavigate();

    const navigateTo = (id: React.Key) => {
        navigation(`/clinic/${id}`);
    };

    const clinics = [
        {
            name: 'Flora Clinic',
            description: 'We offer special solutions to our patients with our contracted, modern facilities and innovative approach. Your health is our priority.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1631248055158-edec7a3c072b?q=80&w=2061&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Hair Transplant", "Breast Augmentation"
            ],
        },
        {
            name: 'Vanity Cosmetic Surgery',
            description: 'Vanity surgeons are member of international plastic surgery societies such as ISAPS and EBOPRAS.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1519494026892-80bbd2d6fd0d?q=80&w=3428&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Liposuction", "Gastric Sleeve Surgery"
            ],
        },
        {
            name: 'Estpoint',
            description: 'Together with our physicians and team members who are experts in their fields, we provide service with a patient-oriented service approach and multidisciplinary perspective.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1516549655169-df83a0774514?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Hip Replacement", "Hair Transplant"
            ],
        },
        {
            name: 'Estethica',
            description: 'Take a look at our surgical and non-surgical aesthetics, healthy living, hair transplantation and dental aesthetics services.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1524222928538-afb4409a0d70?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Dental Implants"
            ],
        },
        {
            name: 'Floria Clinic',
            description: 'We offer special solutions to our patients with our contracted, modern facilities and innovative approach. Your health is our priority.',
            address: 'Turkey - İstanbul',
            image: 'https://images.unsplash.com/photo-1631248055158-edec7a3c072b?q=80&w=2061&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            services: [
                "Hair Transplant", "Breast Augmentation"
            ],
        },
    ];

    const rows = clinics.map((item) => (
        <Table.Tr key={item.name}>
            <Table.Td>
                <Group gap="sm">
                    <AspectRatio ratio={16 / 9} maw={150}>
                        <Image src={item.image} radius={'sm'} />
                    </AspectRatio>

                    <Anchor underline="never" c={'black'} size="lg" ml={50} fw={500} onClick={() => { navigateTo(1) }}>
                        {item.name}
                    </Anchor>
                </Group>
            </Table.Td>
            <Table.Td>
                {item.services.map((service, index) => (
                    <Badge key={index} color={'blue'} variant="light">
                        {service}
                    </Badge>
                ))}
            </Table.Td>
            <Table.Td>
                <Text fz="md">{item.address}</Text>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <div>
            <Box ml={{ base: '25', md: '25', lg: '80', xl: '100' }} mr={{ base: '25', md: '25', lg: '80', xl: '100' }} mt={"lg"}>
                <Group justify="space-between" wrap="nowrap">
                    <TextInput
                        placeholder="Search Clinics"
                        width='100%'
                        w={'100%'}
                        size="lg"
                        radius={"lg"}
                        leftSection={<IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
                    />
                    <ActionIcon
                        variant="gradient"
                        size="xl"
                        aria-label="Gradient action icon"
                        radius={"md"}
                        gradient={{ from: 'blue', to: 'green' }}
                    >
                        <IconSearch />
                    </ActionIcon>
                </Group>
                <Table.ScrollContainer minWidth={800} mt={"lg"}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false} verticalSpacing="sm">
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Name</Table.Th>
                                <Table.Th>Services</Table.Th>
                                <Table.Th>Location</Table.Th>
                                <Table.Th />
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
                <Center>
                    <Pagination total={10} />
                </Center>

            </Box>
        </div>
    );
}

export default ClinicSearch;