import React from 'react';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import './App.css';
import { HeaderMenu } from './components/header/HeaderMenu';
import { AppShell} from '@mantine/core';
import { useDisclosure, useHeadroom, useViewportSize } from '@mantine/hooks';
import { Footer } from './components/footer/Footer';
import { useLocation } from 'react-router-dom';
import RouterSwitcher from './components/router/RouterSwitcher';

function App() {
  const location = useLocation();
  const [opened, { toggle }] = useDisclosure();
  const { height, width } = useViewportSize();
  const footerPinned = useHeadroom({ fixedAt: height });
  const headerCollapsed = useHeadroom({ fixedAt: 100 });
  const shouldHideHeaderFooter = location.pathname === '/login' || location.pathname === '/signup';

  return (

    <AppShell
       header={shouldHideHeaderFooter ? undefined : { height: 60, collapsed: !headerCollapsed }}
      footer={shouldHideHeaderFooter ? undefined : { height: 60, collapsed: footerPinned }}
    >
       {!shouldHideHeaderFooter && (
      <AppShell.Header>
        <HeaderMenu />
      </AppShell.Header>
       )}

      <AppShell.Main>
        <RouterSwitcher/>
      </AppShell.Main>

      {!shouldHideHeaderFooter && (
      <AppShell.Footer>
        <Footer></Footer>
      </AppShell.Footer>
      )}
      
    </AppShell>


  );
}

export default App;
