import { Button, Container, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { ContactFormDto, EnumContactFormPatient } from './contactFormData';

interface TreatmentPatientProps {
    nextStep: () => void;
    contactForm: ContactFormDto;
    setPatient: (patient: EnumContactFormPatient) => void;
}


function TreatmentPatient(props: TreatmentPatientProps) {
    const [selectedPatient, setSelectedPatient] = useState<EnumContactFormPatient | null>(props.contactForm.patient);
    const handleChange = (value: EnumContactFormPatient) => {
        props.setPatient(value);
        props.nextStep();
        setSelectedPatient(value);
    }

    return (
        <>
            <Container size={'xs'}>

                <Title size="h4" mt="md">Who is this treatment for?</Title>

                <Button onClick={() => handleChange(EnumContactFormPatient.MYSELF)} justify="center" fullWidth
                    variant={selectedPatient === EnumContactFormPatient.MYSELF ? 'light' : 'default'}
                    mt="md">
                    I am looking for myself.
                </Button>

                <Button onClick={() => handleChange(EnumContactFormPatient.SOMEONE_ELSE)} justify="center" fullWidth
                    variant={selectedPatient === EnumContactFormPatient.SOMEONE_ELSE ? 'light' : 'default'}
                    mt="md">
                    I am looking for someone else.
                </Button>

            </Container>
        </>
    );
}

export default TreatmentPatient;