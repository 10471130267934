import { Routes, Route} from "react-router-dom";
import Home from "../../pages/Home/Home";
import { NotFound } from "../../pages/NotFound/NotFound";
import DoctorDetail from "../../pages/Doctor/DoctorDetail";
import ClinicDetail from "../../pages/Clinic/ClinicDetail";
import DoctorSearch from "../../pages/Doctor/DoctorSearch";
import ClinicSearch from "../../pages/Clinic/ClinicSearch";
import ContactFormRequest from "../../pages/ContactForm/ContactFormRequest";
import Login from "../../pages/Login/Login";


const RouterSwitcher = () => (
    <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element= {<Home/>}/>
        <Route path="doctor/:id" element={<DoctorDetail />} /> 
        <Route path="clinic/:id" element={<ClinicDetail />} /> 
        <Route path="/doctors" element= {<DoctorSearch/>}/>
        <Route path="/clinics" element= {<ClinicSearch/>}/>
        <Route path="/login" element= {<Login/>}/>
        <Route path="/contactFormRequest" element= {<ContactFormRequest/>}/>
        {
        /**
         <Route path="doctor/:id" element={<AlternativeDoctorDetail />} /> 
         */
        }             
    </Routes>
);

export default RouterSwitcher;
