import { Avatar, Text, Button, Paper, Group } from '@mantine/core';
import { IconPhoneCall, IconMapPin } from '@tabler/icons-react';
import classes from './DoctorCard.module.css';
import { useNavigate } from 'react-router-dom';

export type Doctor = {
    name: string,
    expertise: string,
    location: string,
    number: string,
    image: string,
}

export interface DoctorProps {
    doctor: Doctor;
}

export function DoctorCard(props: DoctorProps) {
    const navigation = useNavigate();
    const doctor = props.doctor;

    const navigateTo = (id: React.Key) => {
        navigation(`/doctor/${id}`);
      };

    return (
        <Paper radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
            <Avatar
                src={doctor.image}
                size={120}
                radius={120}
                mx="auto"
                bd="1px solid gray.4"
                onClick={() => navigateTo(1)}
            />
            <Text ta="center" fz="lg" fw={500} mt="md">
                {doctor.name}
            </Text>
            <Text ta="center" c="dimmed" fz="sm">
                {doctor.expertise}
            </Text>
            <Group justify="center" wrap="nowrap" gap={10} mt={3}>
                <IconMapPin stroke={1.5} size="1rem" className={classes.icon} />
                <Text fz="xs" c="dimmed">
                    {doctor.location}
                </Text>
            </Group>

            <Group justify="center" wrap="nowrap" gap={10} mt={5}>
                <IconPhoneCall stroke={1.5} size="1rem" className={classes.icon} />
                <Text fz="xs" c="dimmed">
                    {doctor.number}
                </Text>
            </Group>

            <Button variant="default" fullWidth mt="md" fz={'xs'}>
                GET A FREE CONSULTATION
            </Button>
        </Paper>
    );
}