import axios from 'axios';

const SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS;

const API_URL = '/api/v1/doctor/search';

export const searchDoctors = async (query : string, page : number, size : number) => {
    try {
        const response = await axios.get(SERVER_ADDRESS + API_URL, {
            params: {
                searchCriteria: query || '',
                page: page || 0,
                size: size || 5,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching doctors:', error);
        throw error;
    }
};