import { Button, Container, Grid, Group, Input, NumberInput, rem, Tabs, Textarea, Image, Menu, Text, useMantineTheme } from "@mantine/core";
import { ContactFormRequestData } from "./types/contactFormRequest";
import { IconCalendar, IconCheck, IconChevronDown, IconHttpDelete, IconInfoCircle, IconMessageCircle, IconMessageReply, IconPackage, IconPhoto, IconRecycle, IconRowRemove, IconSquareCheck, IconTrash, IconUsers } from "@tabler/icons-react";
import { Carousel } from "@mantine/carousel";
import classes from './ContactFormRequestContent.module.css';
import { useEffect, useState } from "react";

function ContactFormRequestContent() {
    const [activeTab, setActiveTab] = useState<string | null>('contactInfo');
    const theme = useMantineTheme();
    const iconStyle = { width: rem(16), height: rem(16) };
    const images = [
        'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-1.png',
        'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-2.png',
        'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png',
        'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-1.png',
        'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-2.png',
        'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png',
    ];

    return (
        <Container size='100%'>
            <Tabs classNames={classes} value={activeTab} onChange={setActiveTab}>
                <Tabs.List grow>
                    <Tabs.Tab value="contactInfo" leftSection={<IconInfoCircle style={iconStyle} color={theme.colors.blue[6]} stroke={2}/>}>
                        Contact Info
                    </Tabs.Tab>
                    <Tabs.Tab value="gallery" leftSection={<IconPhoto style={iconStyle} color={theme.colors.blue[6]} stroke={2}/>}>
                        Gallery
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="contactInfo">
                    <Grid>
                        <Grid.Col span={6}>
                            <Container>
                                <Input.Wrapper label="First Name" mt={"md"}>
                                    <Input contentEditable={false} value="Selin" />
                                </Input.Wrapper>

                                <Input.Wrapper label="Last Name" mt={"md"}>
                                    <Input contentEditable={false} value="Karaca" />
                                </Input.Wrapper>

                                <Input.Wrapper label="Email" mt={"md"}>
                                    <Input contentEditable={false} value="selinkaraca@gmail.com" />
                                </Input.Wrapper>

                                <Grid>
                                    <Grid.Col span={4}>
                                        <NumberInput
                                            label="Country Code"
                                            placeholder="+90"
                                            prefix='+'
                                            value="+90"
                                            contentEditable={false}
                                            mt="md"
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={8}>
                                        <NumberInput
                                            label="Phone"
                                            placeholder="500 1234566"
                                            hideControls
                                            value="500 12 45 66"
                                            contentEditable={false}
                                            mt="md"
                                        />
                                    </Grid.Col>
                                </Grid>

                                <Input.Wrapper label="Contact Date" mt={"md"} >
                                    <Input contentEditable={false} value="14.08.1990 10:43" />
                                </Input.Wrapper>

                                <Menu
                                    transitionProps={{ transition: 'pop-bottom-right' }}
                                    position="bottom-end"                                    
                                    width={300}                                    
                                    withinPortal
                                >
                                    <Menu.Target>
                                        <Button
                                            mt={"lg"}
                                            fullWidth
                                            rightSection={
                                                <IconChevronDown style={{ width: rem(18), height: rem(18) }} stroke={2} />
                                            }
                                            pr={12}
                                        >
                                            Pending
                                        </Button>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item
                                            leftSection={
                                                <IconMessageReply
                                                    style={{ width: rem(20), height: rem(20) }}
                                                    color={theme.colors.blue[6]}
                                                    stroke={2}
                                                />
                                            }                                            
                                        >
                                            Replied
                                        </Menu.Item>
                                        <Menu.Item
                                            leftSection={
                                                <IconSquareCheck
                                                    style={{ width: rem(20), height: rem(20) }}
                                                    color={theme.colors.blue[6]}
                                                    stroke={2}
                                                />
                                            }                                      
                                        >
                                            Done
                                        </Menu.Item>
                                        <Menu.Item
                                            leftSection={
                                                <IconTrash
                                                    style={{ width: rem(20), height: rem(20) }}
                                                    color={theme.colors.blue[6]}
                                                    stroke={2}
                                                />
                                            }
                                        >
                                            Delete
                                        </Menu.Item>                                      
                                    </Menu.Dropdown>
                                </Menu>

                            </Container>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Container>
                                <Input.Wrapper label="Treatment Type" mt={"md"}>
                                    <Input contentEditable={false} value="Plastic Surgeon" />
                                </Input.Wrapper>

                                <Input.Wrapper label="Treatment Time" mt={"md"}>
                                    <Input contentEditable={false} value="As soon as possible." />
                                </Input.Wrapper>

                                <Input.Wrapper label="Preferred Communication Type" mt={"md"}>
                                    <Input contentEditable={false} value="EMAIL" />
                                </Input.Wrapper>

                                <Textarea
                                    label="Note"
                                    value="This is the note for the contact."
                                    contentEditable={false}
                                    mt="md"
                                    maxLength={250}
                                    autosize
                                    minRows={4}
                                    maxRows={4}
                                />

                            </Container>
                        </Grid.Col>
                    </Grid>
                </Tabs.Panel>

                <Tabs.Panel value="gallery">
                    <Container mt={"md"}>
                        <Carousel
                            key={activeTab}
                            styles={{
                                controls: { insetInlineStart: 0, insetInlineEnd: 0 },
                                indicators: { insetInlineStart: 0, insetInlineEnd: 0 }
                            }}
                            withIndicators
                            loop
                            autoFocus
                            height={500}>

                            {images.map((image) => (
                                <Carousel.Slide> <Image src={image} key={image} radius="sm" /></Carousel.Slide>
                            ))}

                        </Carousel>
                    </Container>
                </Tabs.Panel>
            </Tabs>
        </Container>
    )
}

export default ContactFormRequestContent;