import axios from 'axios';

const SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS;

const API_URL = '/api/v1/auth/authenticate';

export const loginRequest = async (email: string, password: String) => {
    try {
        const response = await axios.post(SERVER_ADDRESS + API_URL, {
            email: email || '',
            password: password || 0,
        });
        return response;
    } catch (error) {
        console.error('An error occurred while authenticate:', error);
        throw error;
    }
};