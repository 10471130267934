import { List, ThemeIcon, rem } from '@mantine/core';
import { IconCheck, IconTrophy } from '@tabler/icons-react';

export interface AchievementProps {
    achievements : string[],
}

function DoctorAchievements({achievements}: AchievementProps) {
  return (
    <List
      spacing="xs"
      size="sm"
      center
      icon={
        <ThemeIcon color="blue" size={30} radius="xl">
          <IconCheck style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
      }
    >
    {
        achievements.map((item, index) => (
            <List.Item>{item}</List.Item>
        ))
    }     
    </List>
  );
}

export default DoctorAchievements;