import { Title, Button, Group, Text, List, ThemeIcon, Image, rem, Grid } from '@mantine/core';
import classes from './ClinicDetailBanner.module.css';
import { Carousel } from '@mantine/carousel';
import { IconCheck, IconMapPin, IconWorld } from '@tabler/icons-react';

function ClinicDetailBanner() {
    return (
        <div className={classes.container}>
            <Grid grow justify="center"  ml={{ base: '25', md: '25', lg: '100', xl: '200' }} mr={{ base: '25', md: '25', lg: '100', xl: '200' }}>
                <Grid.Col span={6}>
                    <Carousel styles={{
                        controls: {insetInlineStart: 0, insetInlineEnd: 0},
                        indicators:{insetInlineStart: 0, insetInlineEnd: 0}}}  withIndicators = {true} loop height={350}>                    
                        <Carousel.Slide>
                            <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F51lYryoEOjBUyRa3vFYzbC%2F800d472764666b81fc64ae7b7e318882%2FDSC09224.JPG%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75'} radius="sm"
                            style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F3VqTqH7pKLwwWKq3RAoYxM%2Fc7ca0441111c6d40bc702e3e7ae55fc7%2FDSC09225.JPG%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75'} radius="sm" 
                            style={{ width: "100%", height: "100%", objectFit: 'cover' }}/>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F7kwvmFu6uCvU9lwxZfwB5O%2F06fee7dc7c1dfba4761302aaf138bf38%2Ferdem-1.jpg%3Fw%3D1080%26q%3D100%26h%3D1080%26fm%3Dwebp&w=1080&q=75'} radius="sm" 
                            style={{ width: "100%", height: "100%", objectFit: 'cover' }}/>
                        </Carousel.Slide>
                    </Carousel>
                </Grid.Col>
                <Grid.Col span={6}> <div className={classes.content}>
                    <Title className={classes.title}>
                        Flora Clinic
                    </Title>
                    <Group wrap="nowrap" gap={10} mt={'sm'}>
                        <IconMapPin stroke={1.5} size="1.5rem" className={classes.icon} />
                        <Text className={classes.expertise}>
                            İstanbul, Turkey
                        </Text>
                    </Group>
                    <Group wrap="nowrap" gap={10} mt={'sm'}>
                        <IconWorld stroke={1.5} size="1.5rem" className={classes.icon} />
                        <Text c={'white'} className={classes.expertise}>
                            Dutch, English, French, German, Italian, Spanish
                        </Text>
                    </Group>

                    <List
                        mt={30}
                        spacing="md"
                        size="md"
                        icon={
                            <ThemeIcon size={20} radius="xl">
                                <IconCheck style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                            </ThemeIcon>
                        }
                    >
                        <List.Item c="white">
                            Hair Transplant
                        </List.Item>
                        <List.Item c="white">
                            Breast Augmentation
                        </List.Item>
                    </List>

                    <Group mt={30}>
                        <Button radius="xl" size="lg" className={classes.control}>
                            Get Contact
                        </Button>
                    </Group>
                </div></Grid.Col>
            </Grid>
        </div>
    )
}

export default ClinicDetailBanner;