import { Carousel } from '@mantine/carousel';
import { Image, Paper, SimpleGrid } from '@mantine/core';

interface BeforeAfterProps {
    setImageOpened: (imageOpened: boolean) => void;
}

const images = [
    'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F46rUq4qT00FMikmD2hW98V%2Fd96f8a3460a6129290381fd81650b7ce%2Fb-dr-mehmet-erdogan-md-821.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75',
    'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F1mCwxCd4nBGKcBo0Xukz6P%2F6afb73b135f0268a72bbfd98c8c02357%2Fa-dr-mehmet-erdogan-md-821.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75',
    'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F2o7jazLnWtn1cPA2pYaC8E%2F97489f73a5303990e2ad5543667681f4%2Fb-dr-mehmet-erdogan-md-822.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75',
    'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F2v7d02e39eFO6EC5iP63fJ%2F542860569f5b0a19a7c70128b2fb166b%2Fa-dr-mehmet-erdogan-md-822.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75',
    'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F3hcl0hX4Y15DjN90Cf3edk%2Fbca7206bdd033ea1dd0352811b8b8c5c%2Fb-dr-mehmet-erdogan-md-1578.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75',
    'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F3gL6YV1KUTtDRFmxx3jxsE%2F51c5a35c8a0ccbc1bdc59d16151baf3a%2Fa-dr-mehmet-erdogan-md-1578.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75',
];

function BeforeAfter(props: BeforeAfterProps) {
    return (
        <Carousel
            styles={{
            controls: {insetInlineStart: 0, insetInlineEnd: 0},
            indicators:{insetInlineStart: 0, insetInlineEnd: 0}}}
            withIndicators
            height={300}
            slideSize={{ base: '100%', sm: '50%', md: '33.333333%' }}
            slideGap={{ base: 'lg', sm: 'md' }}
            loop
            align="start"
        >

            {images.map((image, index) => (
                <Carousel.Slide>
                    <Paper withBorder shadow="md" p="sm" radius="md"  h={'100%'} >
                        <SimpleGrid spacing={5} cols={2} h={'100%'} onClick={() => props.setImageOpened(true)}>
                            <Image src={image} key={index} radius="sm" h={'100%'} />
                            <Image src={images[index+1]} key={index+1} radius="sm" h={'100%'} />
                        </SimpleGrid>
                    </Paper>
                </Carousel.Slide>
            ))}

        </Carousel>
    );
}

export default BeforeAfter;