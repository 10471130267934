import { Button, Grid, Group, Modal, Paper, SimpleGrid, Title, Image } from "@mantine/core";
import ClinicDetailBanner from "../../components/clinic/ClinicDetailBanner";
import ClinicDetailAbout from "../../components/clinic/ClinicDetailAbout";
import classes from './ClinicDetail.module.css';
import BeforeAfter from "../../components/doctor/BeforeAfter";
import { useState } from "react";
import { IconArrowRight } from "@tabler/icons-react";
import BeforeAfterGallery from "../../components/doctor/BeforeAfterGallery";

function ClinicDetail() {
    const [galleryOpened, setGalleryOpened] = useState(false);
    const [imageOpened, setImageOpened] = useState(false);

    return (
        <>
            <Modal
                opened={imageOpened}
                size={1000}
                padding={0}
                withCloseButton
                onClose={() => setImageOpened(false)}
            >
                <SimpleGrid m={'md'} spacing={5} cols={2} h={'100%'}>
                    <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F46rUq4qT00FMikmD2hW98V%2Fd96f8a3460a6129290381fd81650b7ce%2Fb-dr-mehmet-erdogan-md-821.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75'} radius="sm" />
                    <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F1mCwxCd4nBGKcBo0Xukz6P%2F6afb73b135f0268a72bbfd98c8c02357%2Fa-dr-mehmet-erdogan-md-821.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75'} radius="sm" />
                </SimpleGrid>
            </Modal>

            <Modal
                opened={galleryOpened}
                size={'xl'}
                padding={0}
                withCloseButton
                onClose={() => setGalleryOpened(false)}
            >
                <BeforeAfterGallery />
            </Modal>
            <ClinicDetailBanner />
            <Grid ml={{ base: '25', md: '25', lg: '100', xl: '200' }} mr={{ base: '25', md: '25', lg: '100', xl: '200' }} mt={'lg'} mb={'lg'}>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                    <ClinicDetailAbout />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                    <Paper withBorder shadow="md" p="sm" radius="md">
                        <Group mb={'md'} justify="space-between">
                            <Title className={classes.timelineTitle}>Before - After Photos</Title>
                            <Button
                                onClick={() => setGalleryOpened(true)}
                                variant="default"
                                rightSection={<IconArrowRight size={14} />}>

                                See All Photos

                            </Button>
                        </Group>
                        <BeforeAfter setImageOpened={setImageOpened} />
                    </Paper>
                </Grid.Col>
            </Grid>

        </>
    )
}

export default ClinicDetail;