import { useForm, isNotEmpty, isEmail, hasLength } from '@mantine/form';
import { Button, Group, TextInput, NumberInput, Container, Title, Textarea, Grid, Checkbox, Anchor, Center } from '@mantine/core';
import { ContactFormDto, TelephoneNumber } from './types/contactFormData';
import { useState } from 'react';
import FileUpload from './FileUpload';

interface ContactDetailsProps {
    contactForm: ContactFormDto;
    setName: (name: string) => void
    setLastName: (lastname: string) => void
    setEmail: (email: string) => void
    setTelephoneNumber: (telephoneNumber: TelephoneNumber) => void
    setNote: (note: string) => void   
    setFiles: (files: File[]) => void
    submitForm: () => void
}

function ContactDetail(props: ContactDetailsProps) {
    const [termsOfServiceApproved, setTermsOfServiceApproved] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            firstname: props.contactForm.firstname,
            lastname: props.contactForm.lastname,
            email: props.contactForm.email,
            note: props.contactForm.note,
            telephoneNumber: {
                countryCode: props.contactForm.telephoneNumber.countryCode,
                number: props.contactForm.telephoneNumber.number
            },
        },

        validate: {
            firstname: hasLength({ min: 2, max: 100 }, 'Name must be 2-100 characters long'),
            lastname: hasLength({ min: 2, max: 100 }, 'Last Name must be 2-100 characters long'),
            email: isEmail('Invalid email'),
            telephoneNumber: {
                countryCode: isNotEmpty('Enter country code'),
                number: isNotEmpty('Enter your phone number'),
            }
        },

    });

    const uploadFiles = (files: File[]) => {        
        setUploadedFiles(files);
       
        {files.map((file, index) => (
            console.log("Upload Files: " + file.name)       
        ))}
    }

    const handleSubmit = (values: typeof form.values) => {
        props.setName(values.firstname);
        props.setLastName(values.lastname);
        props.setEmail(values.email);
        props.setNote(values.note);
        props.setTelephoneNumber(JSON.parse(JSON.stringify(values.telephoneNumber)));
        props.setFiles(uploadedFiles);
        props.submitForm();
    };

    return (
        <Container size={'xs'}>

            <Title size="h4" mt="md">Please provide your contact details.</Title>

            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <TextInput
                    mt="md"
                    label="First Name"
                    placeholder="First Name"
                    withAsterisk
                    key={form.key('firstname')}
                    {...form.getInputProps('firstname')}
                />
                <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    withAsterisk
                    mt="md"
                    key={form.key('lastname')}
                    {...form.getInputProps('lastname')}
                />
                <TextInput
                    label="E-mail"
                    placeholder="your@mail.com"
                    withAsterisk
                    mt="md"
                    key={form.key('email')}
                    {...form.getInputProps('email')}
                />

                <Grid>
                    <Grid.Col span={4}>
                        <NumberInput
                            label="Country Code"
                            placeholder="+90"
                            withAsterisk
                            prefix='+'
                            mt="md"
                            key={form.key('telephoneNumber.countryCode')}
                            {...form.getInputProps('telephoneNumber.countryCode')}
                        />
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <NumberInput
                            label="Phone"
                            placeholder="500 1234566"
                            hideControls
                            withAsterisk
                            mt="md"
                            key={form.key('telephoneNumber.number')}
                            {...form.getInputProps('telephoneNumber.number')}
                        />
                    </Grid.Col>
                </Grid>

                <Textarea
                    label="Note"
                    placeholder="You can type your notes here. (optional)"
                    mt="md"
                    maxLength={250}
                    key={form.key('note')}
                    {...form.getInputProps('note')}
                />

                <Group mt={'md'}>
                    <FileUpload uploadFiles={uploadFiles}/>
                </Group>
                
                <Checkbox
                    mt="md"
                    checked={termsOfServiceApproved}
                    required
                    onChange={(event) => setTermsOfServiceApproved(event.currentTarget.checked)}
                    label={
                        <>
                            I accept to Asklepios's {' '}
                            <Anchor href="https://mantine.dev" target="_blank" inherit>
                                terms and conditions
                            </Anchor>
                            , I have read the {' '}
                            <Anchor href="https://mantine.dev" target="_blank" inherit>
                                Privacy Policy
                            </Anchor> and I agree that my given details including health data may be processed by Asklepios for the purpose of obtaining quotes.
                        </>
                    }
                />

                <Group justify="flex-end" mt="lg">
                    <Button fullWidth type="submit">Submit</Button>
                </Group>
            </form>
        </Container>
    );
}

export default ContactDetail;