import { Button, Divider, Grid, Group, Modal, Paper, SimpleGrid, Stack, Title, Image } from '@mantine/core';
import DoctorDetailBanner from '../../components/doctor/DoctorDetailBanner';
import TimelineInfo, { TimelineItem } from '../../components/doctor/TimelineInfo';
import classes from './DoctorDetail.module.css';
import DoctorAchievements from '../../components/doctor/DoctorAchievements';
import BeforeAfterGallery from '../../components/doctor/BeforeAfterGallery';
import BeforeAfter from '../../components/doctor/BeforeAfter';
import { IconArrowRight } from '@tabler/icons-react';
import { useState } from 'react';

function DoctorDetail() {
    const [galleryOpened, setGalleryOpened] = useState(false);
    const [imageOpened, setImageOpened] = useState(false);

    const doctorDetail = {
        education: [
            {
                startYear: '2002',
                graduateYear: '2006',
                department: 'Faculty of Medicine',
                university: 'Yeditepe University',
                country: 'Turkey'
            },
            {
                startYear: '2008',
                graduateYear: '2012',
                department: 'Faculty of Medicine',
                university: 'İstanbul University',
                country: 'Turkey'
            }
        ],
        career: [
            {
                startYear: '2010',
                endYear: '2014',
                title: 'Plastic Surgeon',
                name: 'Konya Numune Hospital',
                description: '',
                country: 'Turkey'
            },
            {
                startYear: '2014',
                endYear: '',
                title: 'Plastic Surgeon',
                name: 'Konya Medline Hospital',
                description: '',
                country: 'Turkey'
            }
        ],
        achievements: [
            "Member of the Turkish Medical Association",
            "Member of the European Board of Plastic, Reconstructive and Aesthetic Surgery",
            "Member of the Turkish Board of Plastic Surgery",
            "Turkish Healthcare Travel Council - Board Of Directors"
        ]

    };

    const educationItems: TimelineItem[] = doctorDetail.education.map(item => ({
        startDate: item.startYear,
        endDate: item.graduateYear || 'Present',
        title: `${item.department} at ${item.university}`,
        country: item.country
    }));

    const careerItems: TimelineItem[] = doctorDetail.career.map(item => ({
        startDate: item.startYear,
        endDate: item.endYear || 'Present',
        title: `${item.title} at ${item.name}`,
        country: item.country
    }));

    return (
        <div >
            <Modal
                opened={imageOpened}
                size={1000}
                padding={0}
                withCloseButton
                onClose={() => setImageOpened(false)}
            >
                <SimpleGrid m={'md'} spacing={5} cols={2} h={'100%'}>
                        <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F46rUq4qT00FMikmD2hW98V%2Fd96f8a3460a6129290381fd81650b7ce%2Fb-dr-mehmet-erdogan-md-821.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75'} radius="sm"  />
                        <Image src={'https://www.qunomedical.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fkfkw517g6gvn%2F1mCwxCd4nBGKcBo0Xukz6P%2F6afb73b135f0268a72bbfd98c8c02357%2Fa-dr-mehmet-erdogan-md-821.jpg%3Fw%3D1920%26q%3D100%26fm%3Dwebp&w=1920&q=75'}  radius="sm" />
                </SimpleGrid>
            </Modal>

            <Modal
                opened={galleryOpened}
                size={'xl'}
                padding={0}
                withCloseButton
                onClose={() => setGalleryOpened(false)}
            >
                <BeforeAfterGallery/>
            </Modal>

            <DoctorDetailBanner></DoctorDetailBanner>

            <Grid ml={{ base: '25', md: '25', lg: '100', xl: '200' }} mr={{ base: '25', md: '25', lg: '100', xl: '200' }} mt={'lg'} mb={'lg'}>

                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Paper withBorder shadow="md" p="sm" radius="md">
                        <Title mb={'md'} className={classes.timelineTitle}>Education</Title>
                        <Divider my="md" />
                        <TimelineInfo timelineItems={educationItems} />
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Paper withBorder shadow="md" p="sm" radius="md">
                        <Title mb={'md'} className={classes.timelineTitle}>Career</Title>
                        <Divider my="md" />
                        <TimelineInfo timelineItems={careerItems} />
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                    <Paper withBorder shadow="md" p="sm" radius="md">
                        <Title mb={'md'} className={classes.timelineTitle}>Professional Achievements & Awards</Title>
                        <Divider my="md" />
                        <DoctorAchievements achievements={doctorDetail.achievements} />
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                    <Paper withBorder shadow="md" p="sm" radius="md">
                        <Group mb={'md'} justify="space-between">
                            <Title className={classes.timelineTitle}>Before - After Photos</Title>
                            <Button
                                onClick={() => setGalleryOpened(true)}
                                variant="default"
                                rightSection={<IconArrowRight size={14} />}>

                                See All Photos

                            </Button>
                        </Group>
                        <BeforeAfter setImageOpened ={setImageOpened}/>
                    </Paper>
                </Grid.Col>
            </Grid>
        </div>
    );
}

export default DoctorDetail;