import { Button, Container, Title } from '@mantine/core';
import { useState } from 'react';
import { ContactFormDto, EnumContactFormCommunicationType } from './types/contactFormData';

interface TreatmentCommunicationTypeProps {
    nextStep: () => void;
    contactForm: ContactFormDto;
    setCommunicationType: (communication: EnumContactFormCommunicationType) => void;
}


function TreatmentCommunicationType(props: TreatmentCommunicationTypeProps) {
    const [selectedCommunicationType, setSelectedCommunicationType] = useState<EnumContactFormCommunicationType | null>(props.contactForm.communicationType);
    const handleChange = (value: EnumContactFormCommunicationType) => {
        props.setCommunicationType(value);
        props.nextStep();
        setSelectedCommunicationType(value);
    }

    return (
        <>
            <Container size={'xs'}>

                <Title size="h4" mt="md">What is your preferred way of communication?</Title>

                <Button onClick={() => handleChange(EnumContactFormCommunicationType.PHONE)} justify="center" fullWidth
                    variant={selectedCommunicationType === EnumContactFormCommunicationType.PHONE ? 'light' : 'default'}
                    mt="md">
                    Phone
                </Button>

                <Button onClick={() => handleChange(EnumContactFormCommunicationType.EMAIL)} justify="center" fullWidth
                    variant={selectedCommunicationType === EnumContactFormCommunicationType.EMAIL ? 'light' : 'default'}
                    mt="md">
                    E-Mail
                </Button>

                <Button onClick={() => handleChange(EnumContactFormCommunicationType.WHATSAPP)} justify="center" fullWidth
                    variant={selectedCommunicationType === EnumContactFormCommunicationType.WHATSAPP ? 'light' : 'default'}
                    mt="md">
                    WhatsApp
                </Button>

            </Container>
        </>
    );
}

export default TreatmentCommunicationType;