import { Button, Container, Title } from '@mantine/core';
import { useState } from 'react';
import { ContactFormDto, EnumTreatment } from './types/contactFormData';

interface TreatmentTypeProps {
    nextStep: () => void;
    contactForm: ContactFormDto;
    setTreatment: (treatmentType: EnumTreatment) => void;
}


function TreatmentType(props: TreatmentTypeProps) {
    const [selectedTreatment, setSelectedTreatment] = useState<EnumTreatment | null>(props.contactForm.treatment);
    const handleChange = (value: EnumTreatment) => {
        props.setTreatment(value);
        props.nextStep();
        setSelectedTreatment(value);
    }

  return (
        <>
            <Container size={'xs'}>

                <Title size="h4" mt="md">What would you like to do?</Title>

                <Button onClick={() => handleChange(EnumTreatment.HAIR_TRANSPLANTATION)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.HAIR_TRANSPLANTATION ? 'light' : 'default'}
                    mt="md">
                    Hair Transplant
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.ORAL_DENTAL_HEALTH)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.ORAL_DENTAL_HEALTH ? 'light' : 'default'}
                    mt="md">
                    Dental
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.RHINOPLASTY)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.RHINOPLASTY ? 'light' : 'default'}
                    mt="md">
                    Rhinoplasty
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.BREAST_SURGERY)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.BREAST_SURGERY ? 'light' : 'default'}
                    mt="md">
                    Breast Surgery
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.PLASTIC_SURGERY)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.PLASTIC_SURGERY ? 'light' : 'default'}
                    mt="md">
                    Plastic Surgery
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.OBESITY_SURGERY)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.OBESITY_SURGERY ? 'light' : 'default'}
                    mt="md">
                    Weight Loss
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.REPRODUCTIVE_MEDICINE)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.REPRODUCTIVE_MEDICINE ? 'light' : 'default'}
                    mt="md">
                    Reproductive Medicine
                </Button>

                <Button onClick={() => handleChange(EnumTreatment.OTHER)} justify="center" fullWidth
                    variant={selectedTreatment === EnumTreatment.OTHER ? 'light' : 'default'}
                    mt="md">
                    Other
                </Button>
            </Container>
        </>
    );
}

export default TreatmentType;